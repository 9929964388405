<template>
  <div class="home">
    <!-- topBar -->
    <div class="menu">
      <img
        src="~@/assets/menu_btn.png"
        alt
        class="menubar"
        @click="pup.show = true"
      />
      <div class="shareBox">
        <a href="javascript:;" @click="pup.shareSoft = true" class="share">
          <span></span>
        </a>
        <!-- <a
          href="https://www.facebook.com/sgdts/"
          target="_blank"
          class="facebook"
        >
          <span></span>
        </a> -->
        <a href="https://cafe.naver.com/sgdts" target="_blank" class="cafe">
          <span></span>
        </a>
      </div>

      <!-- <a
          href="https://twitter.com/Sea_you_See_you"
          target="_blank"
          class="twitter"
        >
          <span></span>
        </a> -->
    </div>
    <!-- siderbar -->
    <transition name="fade">
      <div class="siderbar" v-show="pup.show">
        <span @click="pup.show = false" class="closeIcon">
          <img src="~@/assets/close_share.png" alt />
        </span>
        <div class="menuList">
          <div
            :class="actvie == 0 ? 'active' : ''"
            @click="changeMenu(0)"
            class="menutitle"
          >
            <img
              src="~@/assets/main_2.png"
              alt
              v-if="actvie == 0"
              style="margin-left: 3px"
            />
            <img
              src="~@/assets/main_1.png"
              alt
              v-else
              style="margin-left: 3px"
            />
          </div>
          <div
            :class="actvie == 1 ? 'active' : ''"
            @click="changeMenu(1)"
            class="menutitle"
            style="margin-left: 108px"
          >
            <img
              src="~@/assets/title_2_active.png"
              alt
              v-if="actvie == 1"
              style="margin-left: 1px"
            />
            <img
              src="~@/assets/title_2.png"
              alt
              v-else
              style="margin-left: 1px"
            />
          </div>
          <div
            :class="actvie == 3 ? 'active' : ''"
            @click="changeMenu(3)"
            class="menutitle"
          >
            <img
              src="~@/assets/title_3_active.png"
              alt
              v-if="actvie == 3"
              style="margin-left: 4px"
            />
            <img
              src="~@/assets/title_3.png"
              alt
              v-else
              style="margin-left: 4px"
            />
          </div>
          <div
            :class="actvie == 4 ? 'active' : ''"
            @click="changeMenu(4)"
            class="menutitle"
          >
            <img src="~@/assets/title_4_active.png" alt v-if="actvie == 4" />
            <img src="~@/assets/title_4.png" alt v-else />
          </div>
        </div>
        <img src="~@/assets/logo.png" alt class="logo" />
      </div>
    </transition>
    <!-- right icon -->
    <div class="rightIcon">
      <a
        @click="pageNext(1)"
        class="e1"
        :style="`background-image: url(${require('../assets/logo_url.png')});background-size:100% 100%;`"
      >
        <!-- <img src="~@/assets/logo_url.png" alt /> -->
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.egames.sgdtskr.google"
        target="_blank"
        class="app1"
        @click="goToStore(1)"
        style="height: 10vh; width: 10vh; background-size: 100% 100%"
      ></a>
      <!-- http://onesto.re/0000756267 -->
      <a
        href="https://onesto.re/_1u8Oh8u"
        class="app2"
        @click="goToStore(4)"
        style="height: 10vh; width: 10vh; background-size: 100% 100%"
      ></a>
      <!--  http://apps.samsung.com/appquery/appDetail.as?appId=com.ulugame.sgdtskr.galaxy-->
      <a
        href="https://apps.samsung.com/appquery/preOrderDetail.as?contentId=000005757609"
        class="app3"
        @click="goToStore(3)"
        style="height: 10vh; width: 10vh; background-size: 100% 100%"
      ></a>
      <a
        href="https://apps.apple.com/kr/app/2x/id1572600077"
        class="app4"
        @click="goToStore(2)"
        style="height: 10vh; width: 10vh; background-size: 100% 100%"
      ></a>
    </div>
    <!-- swiper -->
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-v">
      <!-- slides -->
      <swiper-slide data-hash="0">
        <div
          class="bg1"
          :style="{
            backgroundImage: `url(${
              today < 1643212800000
                ? require('../assets/bg1.jpg')
                : require('../assets/bgA.png')
            })`,
          }"
        >
          <div class="part1">
            <!-- <img src="~@/assets/z-stop.png" /> -->
            <img src="~@/assets/2x_logo.png" />
            <img
              src="~@/assets/play_btn.png"
              alt
              class="video_btn"
              @click="pup.video = true"
            />
            <!-- <a href="javascript:;" @click="pageNext(2)"></a> -->
            <div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.egames.sgdtskr.google"
                >
                  <img src="~@/assets/Android_btn.png" @click="goToStore(1)" />
                </a>
                <a href="https://apps.apple.com/kr/app/2x/id1572600077">
                  <img src="~@/assets/IOS_btn.png" @click="goToStore(2)" />
                </a>
                <a href="https://onesto.re/_1u8Oh8u">
                  <img src="~@/assets/onesto.png" @click="goToStore(4)" alt />
                </a>
                <a
                  href="https://apps.samsung.com/appquery/preOrderDetail.as?contentId=000005757609"
                >
                  <img src="~@/assets/samsun.png" @click="goToStore(3)" alt />
                </a>
              </div>
            </div>
          </div>
          <img
            src="~@/assets/scroll.png"
            alt
            @click="pageNext(true)"
            class="scroll"
          />
        </div>
      </swiper-slide>
      <swiper-slide data-hash="1">
        <div
          class="bg2"
          :style="{
            backgroundImage: `url(${
              today < 1643212800000
                ? require('../assets/bg2.png')
                : require('../assets/bgB.png')
            })`,
          }"
        >
          <div class="part2">
            <div class="part5">
              <div class="reservation" v-if="today < 1644465600000">
                <!-- <div
                :style="{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  'background-repeat': 'no-repeat',
                  'background-size': '100% 100%',
                  'background-image': `url(${require('@/assets/frame.png')})`,
                }"
              ></div> -->

                <img src="~@/assets/box_bg.png" class="question" />
                <!-- <img
                  src="~@/assets/frame.png"
                  style="
                    position: absolute;
                    width: 55.55vw;

                    height: 140vh;
                    z-index: 100;
                  "
                /> -->
                <!-- <div class="question-see">
                  <img
                    class="question-item"
                    v-for="(item, index) in newImgList"
                    :key="index"
                    :src="item.img"
                    :style="{ visibility: item.show ? 'visible' : 'hidden' }"
                  />
                </div> -->

                <!-- <div class="left" @click="pup.g1=true"></div> -->
                <div class="form">
                  <div class="formContent">
                    <!-- 电话号码 -->
                    <div class="inputPhone">
                      <input
                        type="number"
                        placeholder="'-'를 제외한 숫자만 입력하세요"
                        v-model="inputVal"
                        style="line-height: 2.2vw; height: 2.2vw"
                      />
                    </div>
                    <!-- 选择设备 -->
                    <div class="radioSelect">
                      <div class="select_content">
                        <label for="android">
                          <div
                            style="
                              border: black 1px solid;
                              width: 20px;
                              height: 20px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div
                              v-if="checked == '1'"
                              style="
                                width: 16px;
                                height: 16px;
                                background-color: black;
                              "
                            ></div>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="android"
                          v-model="checked"
                          value="1"
                          @change="handleRadio"
                          v-show="false"
                        />
                        <span class="label">Android</span>
                      </div>
                      <div class="select_content">
                        <label for="ios">
                          <div
                            style="
                              border: black 1px solid;
                              width: 20px;
                              height: 20px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div
                              v-if="checked == '2'"
                              style="
                                width: 16px;
                                height: 16px;
                                background-color: black;
                              "
                            ></div>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="ios"
                          v-model="checked"
                          value="2"
                          @change="handleRadio"
                          v-show="false"
                        />
                        <span class="label">iOS</span>
                      </div>
                    </div>
                    <div class="agreement">
                      <div>
                        <div style="display: flex; align-items: center">
                          <label for="agree">
                            <div
                              style="
                                border: black 1px solid;
                                width: 20px;
                                height: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              <div
                                v-if="isCheck"
                                style="
                                  width: 16px;
                                  height: 16px;
                                  background-color: black;
                                "
                              ></div>
                            </div>
                          </label>
                          <input
                            type="checkbox"
                            id="agree"
                            @change="handleCheck(0, $event)"
                            ref="checkbox"
                            v-model="isCheck"
                            v-show="false"
                          />
                          <label
                            for="agree"
                            class="label"
                            style="margin-left: 8px"
                            >개인정보 수집/이용 및 LMS 수신 동의 [필수]</label
                          >
                        </div>
                        <span class="tag" @click="pup.infoTip = true"
                          >자세히 보기</span
                        >
                      </div>
                      <div>
                        <div style="display: flex; align-items: center">
                          <label for="agree1">
                            <div
                              style="
                                border: black 1px solid;
                                width: 20px;
                                height: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              <div
                                v-if="isCheck1"
                                style="
                                  width: 16px;
                                  height: 16px;
                                  background-color: black;
                                "
                              ></div>
                            </div>
                          </label>
                          <input
                            type="checkbox"
                            id="agree1"
                            @change="handleCheck(1, $event)"
                            ref="checkbox1"
                            v-model="isCheck1"
                            v-show="false"
                          />
                          <label
                            for="agree1"
                            class="label"
                            style="margin-left: 8px"
                            >이벤트 참여 유의사항</label
                          >
                        </div>
                        <span class="tag" @click="pup.actTip = true"
                          >자세히 보기</span
                        >
                      </div>
                      <div>
                        <div style="display: flex; align-items: center">
                          <label for="agree2">
                            <div
                              style="
                                border: black 1px solid;
                                width: 20px;
                                height: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              <div
                                v-if="isCheck2"
                                style="
                                  width: 16px;
                                  height: 16px;
                                  background-color: black;
                                "
                              ></div>
                            </div>
                          </label>
                          <input
                            type="checkbox"
                            id="agree2"
                            @change="handleCheck(2, $event)"
                            ref="checkbox2"
                            v-model="isCheck2"
                            v-show="false"
                          />
                          <label
                            for="agree2"
                            class="label"
                            style="margin-left: 8px"
                            >야간LMS 수신 동의</label
                          >
                        </div>
                        <span class="tag" @click="pup.night = true"
                          >자세히 보기</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <img
                    src="~@/assets/form_submit.png"
                    style="width: 24.5vw; z-index: 250"
                    alt
                    @click="submit"
                    class="submit"
                  />
                </div>
                <!-- <img
                  src="~@/assets/img_left.png"
                  style="
                    width: 32%;
                    height: 15%;
                    position: absolute;
                    left: 8%;
                    bottom: 8.5%;
                  "
                /> -->
              </div>

              <div class="link">
                <div style="display: flex; flex-direction: column">
                  <div style="position: relative">
                    <!-- <img src="~@/assets/gift_icon.png" class="gift_icon" /> -->
                    <a
                      href="https://play.google.com/store/apps/details?id=com.egames.sgdtskr.google"
                      target="_blank"
                      class="e1"
                    >
                      <img src="~@/assets/app1.png" alt @click="goToStore(1)" />
                    </a>
                    <!-- http://onesto.re/0000756267 -->
                    <a href="https://onesto.re/_1u8Oh8u" class="e2">
                      <img src="~@/assets/app2.png" @click="goToStore(4)" alt />
                    </a>
                    <!-- http://apps.samsung.com/appquery/appDetail.as?appId=com.ulugame.sgdtskr.galaxy -->
                    <a
                      href="https://apps.samsung.com/appquery/preOrderDetail.as?contentId=000005757609"
                      class="e3"
                    >
                      <img src="~@/assets/app3.png" @click="goToStore(3)" alt />
                    </a>
                    <a
                      href="https://apps.apple.com/kr/app/2x/id1572600077"
                      class="e4"
                    >
                      <img src="~@/assets/app4.png" @click="goToStore(2)" alt />
                    </a>
                  </div>
                </div>
              </div>
              <!-- <a
              href="https://cafe.naver.com/morninggirl"
              target="_blank"
              class="btn"
            >
              <img src="~@/assets/next2.png" alt />
            </a> -->
            </div>
          </div>
          <img
            src="~@/assets/scroll.png"
            alt
            class="scroll"
            @click="pageNext(true)"
          />
        </div>
      </swiper-slide>
      <swiper-slide data-hash="2" style="height: 0"></swiper-slide>
      <!-- <swiper-slide data-hash="2" style="display: flex; flex-direction: column">
        <div class="bg5">
        
          <img
            src="~@/assets/scroll.png"
            alt
            class="scroll"
            @click="pageNext(true)"
          />
        </div>
      </swiper-slide> -->

      <swiper-slide data-hash="3" style="display: flex; flex-direction: column">
        <div
          class="bg4"
          :style="{
            backgroundImage: `url(
              ${
                today < 1643212800000
                  ? require('../assets/bg4.jpg')
                  : require('../assets/bgC.png')
              })`,
          }"
        >
          <div class="part4">
            <div class="box">
              <a href="https://cafe.naver.com/sgdts/56" target="_blank">
                <a class="e1"></a>
                <img src="~@/assets/button1.png" title="none" />
              </a>
              <a href="https://cafe.naver.com/sgdts/57" target="_blank">
                <a class="e2"></a>
                <img src="~@/assets/button2.png" title="none" />
              </a>
              <a href="https://cafe.naver.com/sgdts/58" target="_blank">
                <a class="e3"></a>

                <img src="~@/assets/button3.png" title="none" />
              </a>
              <!-- <a
                class="e2"
                href="https://cafe.naver.com/morninggirl/106"
                target="_blank"
              ></a>
              <a
                class="e3"
                href="https://cafe.naver.com/morninggirl/105"
                target="_blank"
              ></a> -->
            </div>

            <a
              href="https://cafe.naver.com/sgdts"
              target="_blank"
              style="
                width: 462px;
                height: 129px;
                margin: 0 auto;
                display: block;
                margin-top: 7.5vh;
              "
            >
              <img src="~@/assets/button_last.png" alt />
            </a>
          </div>
          <img
            src="~@/assets/scroll.png"
            alt
            class="scroll"
            @click="pageNext(true)"
          />
        </div>
      </swiper-slide>
      <!-- new -->

      <swiper-slide data-hash="4">
        <div
          class="bg3"
          :style="{
            'background-image': `url(
              ${
                today < 1643212800000
                  ? require('../assets/bg3.jpg')
                  : require('../assets/bgD.png')
              })`,
          }"
        >
          <div class="part3">
            <img
              src="~@/assets/L_btn.png"
              style="width：62px;height:89px;cursor:pointer;"
              @click="lightIt(i, 'min')"
            />

            <swiper
              @slideChange="slideChange"
              :options="{
                loop: true,
                autoplay: true,
              }"
              class="swiper-h"
              ref="mSwiper"
            >
              <swiper-slide
                style="background: black; width: 100%; height: 100%"
                :index="index"
                v-for="(item, index) in 5"
                :key="index"
              >
                <img
                  style="background: gray; width: 100%; height: 100%"
                  :src="
                    today < 1643212800000
                      ? require(`../assets/swiper_${index + 1}.png`)
                      : require(`../assets/banner${index + 1}.jpg`)
                  "
                  alt
                />
              </swiper-slide>
            </swiper>
            <img
              @click="lightIt(i, 'plus')"
              src="~@/assets/R_btn.png"
              style="width：62px;height:89px;cursor: pointer"
            />
          </div>
          <div
            style="
              display: flex;
              width: 280px;
              margin: 0 auto;
              align-items: center;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            <div
              style="display: flex; align-items: center"
              v-for="(item, index) in isClassList"
              :key="index"
            >
              <img
                src="~@/assets/off.png"
                :class="item ? 'light' : 'notlight'"
                v-if="isClassList.length <= 5"
              />
              <div
                v-if="index < 4"
                style="height: 4px; width: 40px; background-color: white"
              ></div>
            </div>
          </div>
          <img
            src="~@/assets/scrollUp.png"
            alt
            class="scroll"
            @click="changeMenu(0)"
          />
        </div>
        <div class="swiper-scrollbar" slot="scrollbar">
          <div class="swiper-scrollbar-drag"></div>
        </div>
      </swiper-slide>

      <swiper-slide class="swiper-footer">
        <div class="part5">
          <img src="~@/assets/copyright.png" alt />
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
    <!-- pop video -->
    <div v-if="pup.video">
      <div class="mfp-bg"></div>
      <div class="videoPlayer">
        <span @click="pup.video = false" class="closeShare"></span>
        <img src alt />
        <iframe
          ref="video"
          width="1024"
          height="576"
          src="https://www.youtube.com/embed/v-yVUXuNzI0?rel=0&amp;autoplay=1"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <!-- popup -->
    <div v-show="pup.wait">
      <div class="mfp-bg"></div>
      <div class="pop-wait">
        <div class="ok" @click="closeWait"></div>
      </div>
    </div>
    <!-- popup share -->
    <div class="shareSoft" v-show="pup.shareSoft">
      <div class="mfp-bg"></div>
      <div class="pop-share">
        <!-- <img
          src="~@/assets/share_title.png"
          style="
            position: absolute;
            top: 38px;
            left: 50%;
            transform: translateX(-50%);
          "
        /> -->
        <span @click="pup.shareSoft = false" class="closeShare1"></span>
        <div class="shareIcon">
          <ShareNetwork
            network="facebook"
            :url="baseUrl"
            title="<2X>사전예약 페이지"
            description="막힘없은 탑 클래스!  인기 MMORPG <2X> 사전예약 및 이벤트 진행 중!"
            hashtags="공식 홈페이지"
          >
            <img src="~@/assets/face_btn.png" alt />
          </ShareNetwork>
          <!-- <ShareNetwork
            network="twitter"
            :url="baseUrl"
            title="Sea you, See you"
            description="로맨틱 시뮬레이션 게임, 지금 사전예약 중!"
            hashtags="공식 홈페이지"
          >
            <img src="~@/assets/twitterHover.png" alt />
          </ShareNetwork> -->
          <div id="kakaostory-share-button">
            <a href="https://open.kakao.com/o/glBKN2Pd"></a>
          </div>
        </div>
      </div>
    </div>
    <!-- popup act tip -->
    <div v-show="pup.actTip">
      <div class="mfp-bg"></div>
      <div class="actBox position" @click="pup.actTip = false">
        <!-- <img
          src="~@/assets/close_share.png"
          class="closeBtn"
          @click="pup.actTip = false"
        /> -->
      </div>
    </div>
    <!-- popup info collect -->
    <div v-show="pup.infoTip">
      <div class="mfp-bg"></div>
      <div class="infoBox position" @click="pup.infoTip = false">
        <!-- <img
          src="~@/assets/closeBtn.png"
          class="closeBtn"
          @click="pup.infoTip = false"
        />-->
      </div>
    </div>
    <!--popup submit success -->
    <div v-show="pup.submitSuccess">
      <div class="mfp-bg"></div>
      <div class="successBox position">
        <img
          src="~@/assets/close_share.png"
          class="closeBox"
          @click="pup.submitSuccess = false"
        />
        <div class="btnBoth">
          <a href="javascript:;" @click="setPage">
            <img src="~@/assets/leftBtn.png" alt />
          </a>
          <a href="https://cafe.naver.com/sgdts" target="_blank">
            <img src="~@/assets/rightBtn.png" alt />
          </a>
        </div>
      </div>
    </div>
    <!-- popup error tip -->
    <div v-show="pup.errorTip">
      <div class="mfp-bg"></div>
      <div class="errorBox position">
        <div class="tipText" v-html="tip"></div>
        <img
          src="~@/assets/closeBtn.png"
          class="closeBtn"
          @click="pup.errorTip = false"
        />
      </div>
    </div>
    <!-- pop night  -->
    <div v-show="pup.night">
      <div class="mfp-bg"></div>
      <div class="night position" @click="pup.night = false">
        <!-- <img class="closeBtn" /> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const selected = require("@/assets/checked.png");
const select = require("@/assets/check.png");
import { request } from "@/util/index.js";
let today = new Date().getTime();
//import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  name: "carrousel",
  data() {
    return {
      today,
      swiperOption: {
        observer: true,
        direction: "vertical",
        slidesPerView: "auto",
        spaceBetween: 0,
        mousewheel: true,
        hashNavigation: true,
      },
      otherOption: {
        observer: true,
        slidesPerView: "auto",
        spaceBetween: 0,
      },
      isClassList: [true, false, false, false, false],
      swiperOption1: {
        // effect: "flip",
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseUrl: process.env.VUE_APP_BASEURL,
      pup: {
        wait: false,
        show: false,
        errorTip: false,
        actTip: false,
        infoTip: false,
        night: false,
        submitSuccess: false,
        shareSoft: false,
        video: false,
      },
      inputVal: "",
      actvie: 0,
      checked: 1,
      isCheck: 1,
      isCheck1: 1,
      isCheck2: 1,
      imgs: [selected, select],
      chosen: [selected, selected, selected],
      tip: "",
      i: 0,
      imgList: [
        "puzzle_1",
        "puzzle_2",
        "puzzle_3",
        "puzzle_4",
        "puzzle_5",
        "puzzle_6",
        "puzzle_7",
        "puzzle_8",
        "puzzle_9",
      ],
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    mSwiper() {
      return this.$refs.mSwiper.swiper;
    },
    newImgList() {
      return this.imgList.map((item, index) => {
        return { img: require(`@/assets/${item}.png`), show: index !== 0 };
      });
    },
  },
  watch: {
    "pup.video": {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            console.log(val, this.$refs["video"]);
            //this.$refs["video"].click();
          });
        }
      },
      immediate: true,
      deep: true,
    },
    $route(to) {
      this.actvie = to.hash.split("#")[1];
    },
  },
  async mounted() {
    if (new Date().getTime() > 1644454800000) {
      location.href =
        "https://play.google.com/store/apps/details?id=com.egames.sgdtskr.google";
    }

    let b = this.$refs["mySwiper"].$children;
    b.forEach((element, index) => {
      if (index < 4) {
        element.height = window.innerHeight;
      }
    });

    // 사용할 앱의 JavaScript 키를 설정해 주세요.
    // window.Kakao.init("d863bcf230e70aa3b242a3d1c3b8f90d");
    // 스토리 공유 버튼을 생성합니다.
    // window.Kakao.Story.createShareButton({
    //   container: "#kakaostory-share-button1",
    //   url: this.baseUrl,
    //   text: "막힘없은 탑 클래스!  인기 MMORPG <2X> 사전예약 및 이벤트 진행 중!",
    // });
    // this.actvie = this.$route.hash.split("#")[1] || 0;
    // await fetch(`${this.baseUrl}/api/website/reserve/web/accessCount`, {
    //   method: "PUT",
    //   body: JSON.stringify({
    //     gameId: "100160",
    //     activityId: "11",
    //   }),
    //   headers: new Headers({
    //     "Content-Type": "application/json",
    //   }),
    // });
  },
  methods: {
    goToStore(channelStore) {
      request({
        url: "/api/website/reserve/web/channelStore",
        data: {
          activityId: "11", //活动ID
          email: "",
          mobile: this.inputVal,
          channelStore: channelStore,
        },
      });
    },
    slideChange() {
      this.i = (this.mSwiper.activeIndex || 0) % 5;
      this.isClassList = this.isClassList.map(() => {
        return false;
      });
      this.$set(this.isClassList, (this.i + 4) % 5, true);
      //   console.log(7883, s);
      //   $("#showhtml").html("函数触发了");
    },
    lightIt(index = 0, type) {
      console.log(index, this.mSwiper.activeIndex);
      let i = this.mSwiper.activeIndex;
      // i = index + 1 || 0;
      this.isClassList = this.isClassList.map(() => {
        return false;
      });
      if (type === "plus") {
        if (i > 4) {
          i = 0;
        }
        i++;
      } else if (type === "min") {
        if (i <= 1) {
          i = 6;
        }
        i--;
      }

      this.i = i;
      this.$refs.mSwiper.swiper.slideTo(Math.abs(i) % 6, 500, false);
      this.$set(this.isClassList, (Math.abs(i) % 6) - 1, true);
    },
    submit() {
      this.setData();
      if (
        this.inputVal.length !== 11 ||
        this.maxlength(this.inputVal) >= 8 ||
        this.inputVal.indexOf("010") == -1
      ) {
        this.tip = "휴대폰 번호를 정확히 입력해주세요";
        this.pup.errorTip = true;
        return false;
      }
      if (!this.isCheck || !this.isCheck1 || !this.isCheck2) {
        this.tip = "개인정보 수집 및 LMS <br/> 수신에 동의해주세요";
        this.pup.errorTip = true;
        return false;
      }
      if (this.inputVal.indexOf("-") !== -1) {
        this.tip = "-제외하고 휴대폰번호만 입력해 주세요";
        this.pup.errorTip = true;
        return false;
      }
      const media = this.$route.params.id || "direct";
      fetch(`${this.baseUrl}/api/website/reserve/web/preRegister`, {
        method: "PUT",
        body: JSON.stringify({
          gameId: "100160",
          activityId: "11",
          channelId: this.checked,
          mobile: this.inputVal,
          platform: "1",
          media,
        }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((res) => {
          return res.json();
        })
        .catch((error) => console.error("Error:", error))
        .then((response) => {
          if (response.code == 0) {
            this.pup.submitSuccess = true;
          } else {
            switch (response.code) {
              case 40516:
                this.tip =
                  "이미 사전예약에 참여하였습니다.<br/> 이벤트에 참여하세요";
                this.pup.errorTip = true;
                break;
              case 500:
                this.tip = "오류 발생";
                this.pup.errorTip = true;
                break;
              case 30100:
                this.tip = "파라미터 결실";
                this.pup.errorTip = true;
                break;
              case 40701:
                this.tip = "이벤트 존재하지 않습니다";
                this.pup.errorTip = true;
                break;
              case 40702:
                this.tip = "이벤트 미 시작";
                this.pup.errorTip = true;
                break;
              case 40703:
                this.tip = "이벤트 종료되었습니다";
                this.pup.errorTip = true;
                break;
              case 40515:
                this.tip = "핸드폰 번호 채워주십시오";
                this.pup.errorTip = true;
                break;
              case 40003:
                this.tip = "사용할 수 없는 핸드폰 번호";
                this.pup.errorTip = true;
                break;
              case 40300:
                this.tip = "조금후에 다시 시도 부탁드립니다";
                this.pup.errorTip = true;
                break;
              default:
                this.tip = "error";
                this.pup.errorTip = true;
            }
          }
        });
    },
    maxlength(c) {
      c = c.toString();
      let maxlength = 1,
        length = 1,
        char = "";
      for (let index in c) {
        var tmp = c[index];
        if (char == tmp) {
          length += 1;
        } else {
          char = tmp;
          if (length > maxlength) {
            maxlength = length;
          }
          length = 1;
          char = tmp;
        }
      }
      maxlength = maxlength > length ? maxlength : length;
      return maxlength;
    },
    handleTip() {
      this.tip = "추후 오픈 예정입니다";
      this.pup.errorTip = true;
    },
    setPage() {
      this.pup.submitSuccess = false;
      this.swiper.slideTo(3, 500, false);
    },
    pageNext(val) {
      if (val) {
        console.log(888, val);
        if (typeof val === "number") {
          console.log(888, val);
          this.swiper.slideTo(val, 500, false);
          return;
        }
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
    closeWait() {
      this.pup.wait = false;
    },
    changeMenu(val) {
      this.actvie = val;
      this.swiper.slideTo(val, 500, false);
    },
    handleCheck(x, e) {
      if (e.target.checked) {
        this.chosen[x] = selected;
      } else {
        this.chosen[x] = select;
      }
    },
    handleRadio() {
      if (this.checked == "1") {
        this.imgs[0] = selected;
        this.imgs[1] = select;
      }
      if (this.checked == "2") {
        this.imgs[0] = select;
        this.imgs[1] = selected;
      }
    },

    //埋点
    setData() {
      window.fbq && window.fbq("track", "CompleteRegistration");

      window.ttq.track("CompleteRegistration");
      // window.ttq
    },
  },
};
</script>
<style lang="scss">
html,
body,
.home {
  position: relative;
  height: 100%;
  width: 100%;
}

body {
  background: black;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: white;
  margin: 0;
  padding: 0;
}

.swiper-container,
.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.bg1 {
  background: #000 url(~@/assets/bg1.jpg) center 30% no-repeat;
  display: flex;
  justify-content: center;
}
.bg2 {
  background: #000 url(~@/assets/bg2.png) no-repeat;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  background-position-y: 15%;
}
.bg3 {
  background: #000 url(~@/assets/bg4.jpg) center 15% no-repeat;
}
.bg4 {
  background: #000 url(~@/assets/bg3.jpg) center 15% no-repeat;
  display: flex;
  justify-content: center;
}
.bg5 {
  background: #000 url(~@/assets/bg5.jpg) center 15% no-repeat;
  display: flex;
  justify-content: center;
}

.bg1,
.bg4,
.bg5,
.bg3 {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;
  background-position: top;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transform: translate(0, 0);
  transition: 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(-301px, 0);
  transition: 0.3s ease;
}
.siderbar {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 301px;
  height: 100%;
  z-index: 158;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px 17px 40px 0;
  box-sizing: border-box;
  .closeIcon {
    align-self: flex-end;
    cursor: pointer;
    z-index: 10;
  }
  .logo {
    display: block;
    width: 213px;
    height: 104px;
  }
  .menuList {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    img {
      width: 284px;
      height: 133px;
    }
    // img::after {
    //   content: "2";
    //   height: 100px;
    //   width: 4px;
    //   background: black;
    // }

    .menutitle {
      margin-left: 110px;
      width: 284px;
      position: relative;
    }
    .menutitle::after {
      content: "";
      height: 118px;
      width: 2px;
      background: white;
      position: absolute;
      top: 50%;
      right: 68px;
    }
    .menutitle:nth-child(4)::after {
      height: 0px;
    }

    div {
      cursor: pointer;
    }
    .active {
      margin-left: 110px;
    }
  }
}
.night {
  width: 600px;
  height: 318px;
  background: url(~@/assets/night.png) no-repeat;
  position: absolute;
  .closeBtn {
    width: 126px;
    height: 44px;
    margin: auto;
    left: 0;
    right: 0;
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 64px;
  }
}
.tipText {
  color: #262626;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
}
.closeBox {
  position: absolute;
  right: 0;
  top: -80px;
  cursor: pointer;
}

.position {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 25;
}
.infoBox {
  width: 599px;
  height: 784px;
  background: url(~@/assets/info1.png) no-repeat;
  .closeBtn {
    width: 126px;
    height: 41px;
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 59px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
.currentNum {
  background: url(~@/assets/current.png) no-repeat;
  width: 291px;
  height: 67px;
  margin: 0 auto;
  span {
    float: right;
    margin: 14px 48px 0 0;
    font-size: 20px;
  }
}
.errorBox {
  background: white;
  width: 588px;
  height: 195px;

  border-radius: 10px;
  .closeBtn {
    width: 112px;
    height: 41px;
    margin: 46px auto 0 auto;
    display: block;
    cursor: pointer;
  }
}
.successBox {
  width: 30vw;
  height: 32vh;
  background: url(~@/assets/success.png) no-repeat;
  background-size: 100% 100%;
  .btnBoth {
    position: absolute;
    bottom: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(50%);
    a {
      margin: 0 10px;
    }
  }
}
.actBox {
  width: 599px;
  height: 532px;
  background: url(~@/assets/act.png) no-repeat;
  .closeBtn {
    width: 112px;
    height: 41px;
    position: absolute;
    bottom: 62px;
    display: block;
    cursor: pointer;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.rightIcon {
  position: fixed;
  top: 1vh;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10vh;
  height: 50vh;
  z-index: 22;
  background-color: white;
  cursor: pointer;
  a,
  span {
    width: 10vh;
    height: 10vh;
    display: block;
  }
  .app0 {
    background: url(~@/assets/logo_url.png) no-repeat;
  }
  .app1 {
    background: url(~@/assets/Google.png) no-repeat;
  }
  .app2 {
    background: url(~@/assets/oneStore.png) no-repeat;
  }
  .app3 {
    background: url(~@/assets/btn3.png) no-repeat;
  }
  .app4 {
    background: url(~@/assets/btn4.png) no-repeat;
  }
}
.pop-wait {
  background: url(~@/assets/bgbox.png) no-repeat;
  width: 599px;
  height: 483px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 88;

  .ok {
    width: 120px;
    height: 40px;
    position: absolute;
    bottom: 62px;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;
  }
}
// share
.shareSoft {
  .pop-share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    width: 836px;
    height: 470px;
    .closeShare1 {
      //close_share
      background: url(~@/assets/close_share.png) no-repeat;
      background-size: 100%;
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 20px;
      width: 80px;
      height: 80px;
    }
    .shareIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 617px;
      height: 346px;
      margin: 0 auto;
      background-image: url(~@/assets/a_share.png);
      background-repeat: no-repeat;
      margin-top: 96px;

      span {
        margin: 0 4px;
        cursor: pointer;
        float: left;
        width: 92px;
        height: 92px;
      }
    }
  }
}
.closeShare {
  background: url(~@/assets/close_share.png) no-repeat;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: -75px;
  width: 85px;
  height: 85px;
}
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}
.menu {
  position: fixed;
  z-index: 20;
  display: flex;
  padding: 25px 25px 0 25px;
  justify-content: space-between;
  box-sizing: border-box;
  .shareBox {
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 45px;
    a {
      width: 105px;
      height: 101px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: inline-block;
        width: 87px;
        height: 83px;
      }
    }

    .share span {
      background: url(~@/assets/share.png) no-repeat;
    }
    // .share:hover span {
    //   background: url(~@/assets/shareHover.png) no-repeat;
    // }
    .cafe span {
      background: url(~@/assets/cafe.png) no-repeat;
    }
    // .cafe:hover span {
    //   background: url(~@/assets/cafeHover.png) no-repeat;
    // }
    .facebook span {
      background: url(~@/assets/facebook.png) no-repeat;
    }
    // .facebook:hover span {
    //   background: url(~@/assets/facebookHover.png) no-repeat;
    // }
  }
  .menubar {
    cursor: pointer;
    height: 80px;
  }
}
.tagContents {
  // background: url(~@/assets/contents.png) no-repeat;
  width: 1080px;
  height: 613px;
  position: relative;
  left: -75px;
  right: 0;
  margin: auto;
  transform: scale(0.8);
}
.part1 {
  position: absolute;
  top: 30vh;
  & > :nth-child(1) {
    width: 16.25vw;
  }
  & > :nth-child(3) {
    background: url(~@/assets/card_bg.png) no-repeat;
    width: 51.77vw;
    height: 30vh;
    background-size: 100% 100%;
    margin-top: -3.3vw;
    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
    & > div {
      display: flex;
      flex-wrap: wrap;
      width: 25vw;
      height: 7vw;
      margin-bottom: 1vh;
      align-content: center;

      & > a {
        & > img {
          width: 10vw;

          margin-left: 0px;
          margin-right: 0vw;
        }
        margin-bottom: 0.5vh;
      }
      & > :nth-child(even) {
        margin-left: 10px;
      }
    }
  }

  img {
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  .video_btn {
    width: 10vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
}

.videoPlayer {
  width: 1024px;
  height: 576px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 23;
}
#swiper-container2 .swiper-slide {
  // height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.part5 {
  width: 100vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  .reservation {
    width: 52.55vw;
    height: 18vw;
    position: absolute;
    transform: scale(0.95);
    top: 25.5vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-clip: content-box;
    .question {
      position: absolute;
      width: 100%;

      left: 50%;
      transform: translateX(-50%);
    }
    .question-item {
      display: block;
      width: 33.33%;
      height: 33.33%;
    }
    .question-see {
      line-height: 0px;
      font-size: 0;
      position: absolute;
      width: calc(100% - 4vw);
      height: calc(55.55vw - 4vw);
      top: 27px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .link {
    position: absolute;
    bottom: 6.5vh;
    & img {
      height: 4.5vw;
    }
  }
  .submit {
    cursor: pointer;
    position: absolute;
    top: 17.5vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .form {
    position: absolute;
    top: 6.2vw;
    right: 6%;
    width: 46%;
    height: 20vh;
    z-index: 250;
    .formContent {
      //   margin: 74px 80px 20px 0;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      height: 80%;
      justify-content: space-around;
      .radioSelect {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .label {
          font-size: 1.2rem;
          font-family: "Arial";
          font-weight: bold;
          margin-left: 8px;
          color: black;
        }
        .select_content {
          display: flex;
          align-items: center;
          margin-right: 20%;
        }
      }
      .inputPhone {
        text-align: center;
        width: 100%;

        box-sizing: border-box;
        display: flex;
        border: 1px solid black;
        align-items: center;
        box-shadow: none;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type="number"] {
          -moz-appearance: textfield;
          border: none;
        }
        input:focus {
          outline: none !important;
          box-shadow: none !important;
        }
        input::placeholder {
          color: black;
          font-weight: bold;
        }
        input {
          width: 100%;
          height: 100%;
          display: inline-block;
          box-sizing: border-box;
          text-indent: 10px;
          background: transparent;
          font-size: 1.5rem;
          color: black;
          border: none;
          text-align: center;
          box-shadow: none;
        }
        img {
          margin-left: 15px;
        }
      }
      .agreement {
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          .label {
            color: black;
            font-size: 1rem;
            font-weight: bold;
          }
        }
        .box {
          padding-left: 43px;
        }
        .tag {
          border-radius: 3px;
          padding: 4px 3px;

          font-size: 14px;
          cursor: pointer;
          color: #8b8b8b;
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }
  }
}
.title3 {
  margin: 2% auto 20px auto;
  display: block;
}
.carousel-3d-slide {
  background-color: transparent !important;
}

.part3 {
  margin: 25vh auto 0 auto;
  width: 58vw;
  height: 50vh;
  display: flex;
  align-items: center;
  .swiper-h {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    div.carousel-3d-controls {
      a.prev,
      a.next {
        width: 48px !important;
      }
    }
  }
  .button-prev {
    background: url("~@/assets/L_btn.png") no-repeat;
    width: 48px;
    height: 52px;
    cursor: pointer;
    display: inline-block;
    top: 0;
    position: absolute;
    left: 74px;
  }
  .button-next {
    background: url("~@/assets/R_btn.png") no-repeat;
    width: 48px;
    height: 52px;
    cursor: pointer;
    display: inline-block;
    top: 0;
    position: absolute;
    right: 74px;
  }
  // .swiper-button-next:after {
  //   visibility: hidden;
  // }
  // .swiper-button-prev:after {
  //   visibility: hidden;
  // }
}

.part4 {
  margin-top: 30vh;

  .box {
    display: flex;
    flex-direction: row;
    div {
      width: 17vw;
    }
    a {
      width: 15.5vw;
      height: 20vw;
      margin-right: 1.2vw;
      display: inline-block;
      cursor: pointer;
    }
    img {
      width: 15.5vw;
      height: 3.1vh;
      cursor: pointer;
      margin-top: 10px;
    }
    .e1 {
      background: url("~@/assets/b1.png") no-repeat;
      background-size: 100% 100%;
    }
    .e2 {
      background: url("~@/assets/b2.png") no-repeat;
      background-size: 100% 100%;
    }
    .e3 {
      background: url("~@/assets/b3.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .btn {
    margin: 0 auto;
    width: 157px;
    height: 150px;
    display: block;
  }
}
.swiper-footer {
  height: 80px !important;
  background: black !important;
  & img {
    margin-top: 40px;
  }
}
// .part5 {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   overflow: hidden;
//   .swiper-footer {
//     height: 100px !important;
//     background: black !important;
//   }
//   img {
//     display: block;
//   }
// }

.scroll {
  position: absolute;
  margin: 0 auto;
  bottom: 5px;
  left: 0;
  right: 0;
  z-index: 21;
  cursor: pointer;
  transform: scale(0.8);
}
#kakaostory-share-button {
  width: 79px;
  height: 79px;
  background: url(~@/assets/cacao_btn.png) no-repeat;
  background-repeat: no-repeat;
  background-position: center 0;
  margin-left: 18px;
}
#kakaostory-share-button a {
  display: block;
  width: 100%;
  height: 100%;
}
#kakaostory-share-button a img {
  display: none;
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .scroll {
    transform: scale(0.8);
  }
  .part2 {
    margin-top: 15%;
  }

  //   .part3 {
  //   }
  //   .part1,
  //   .part2,
  //   .part3,
  //   .part4 {
  //     transform: scale(0.8);
  //   }
}
.gift_icon {
  position: absolute;
  bottom: 70px;
  right: 70px;
}
.light {
  box-shadow: 0 0 8px #f4f0f1;
  -webkit-box-shadow: 0 0 8px #f4f0f1;
  -moz-box-shadow: 0 0 8px #f4f0f1;
  outline: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.notlight {
  width: 16px;
  height: 16px;
}
.bottom_bar {
  bottom: 0;
  position: fixed;
  height: 200px;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}
</style>
