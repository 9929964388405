import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSocialSharing from 'vue-social-sharing'
import './style.css'
import 'normalize.css/normalize.css'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.config.productionTip = false
Vue.use(VueSocialSharing);
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')